/* Allow pointer events to occur on Ant Modal */
.ant-modal {
  pointer-events: all;
}

/* Loader */

.loaderContainer {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 48px;
    color: $primary2;
  }
}

/* Alert */

.alertContainer {
  .alert {
    width: 100%;
    max-width: 288px;
    margin: 0 auto;
    border-radius: 12px;
    background-color: $white;
    padding: 24px 16px;
  
    .content {
      text-align: center;
      margin-bottom: 40px;
  
      .heading {
        @include Roboto;
        font-weight: 700;
        font-size: 24px;
        line-height: 28.13px;
        text-align: center;
        color: $black;
        margin-bottom: 24px;
      }

      .subHeading {
        @include Roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 25.92px;
        text-align: center;
        color: $black;
        margin-bottom: 24px;
      }
    }
  
    .actions {
      padding: 0; // Overwrite
  
      .buttonContainer.addSpacing {
        margin-top: 16px;
      } 
    }
  }
}

.alertContainer.selectResident {

}
