/* General */

@mixin BodyFont {
  @include Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.92px;
  color: $black;
}

body {
  @include BodyFont;
  background-color: $white;
}

html, body, #root, .viewContainer {
  height: 100%;
}

.viewContainer {
  position: relative; // For fixed header bar and/or bottom navigation
}

.contentContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.actions {
  width: 100%;
  padding: 0 16px 16px 16px;
}

.buttonContainer.addSpacing {
  margin-top: 16px;
}

.hidden {
  display: none;
}

.center {
  text-align: center;
}

a {
  color: $primary1;

  // TODO: Determine expected styling for links
  &:hover, &:active, &:visited {
    color: $primary1;
  }
}


