/* Header Bar */

.headerBar {
  width: 100%;
  height: calc(117px / 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  .logoContainer {
    img {
      height: 20px;
      width: auto;
    }
  }

  .needHelpButton {
    @include BodyFont;
    line-height: 18.75px;
    color: $primary1;
  }
}

/* Site Heading */

.siteHeader {
  width: 100%;
  height: calc(117px / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  border-bottom: 1px solid #C4C1C4;

  .name {
    @include Roboto;
    font-weight: 700;
    font-size: 18px;
    line-height: 21.09px;
    color: $primary1;
  }

  .station {
    @include Roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    color: $slate;
    text-transform: uppercase;
  }
}

/* Retailer Button */

.retailerButton {
  width: 68px;
  margin: 0 calc((100% - (68px * 4))/8);
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: 68px;
    height: 68px;
    padding: 8px;
    border-radius: 50%;
    border: 1px solid #98AD86;
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
    margin-bottom: 6px;
  }

  .name {
    width: 68px;
    @include Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.41px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Retailer Location Button */

.retailerLocationButton {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 1px solid #C5D1D4;
  padding: 16px 0;

  .imageContainer {
    width: 68px;
    flex: none;
    margin-right: 11px;
    display: flex;
    align-items: center;

    .image {
      width: 68px;
      height: 68px;
      padding: 8px;
      border-radius: 50%;
      border: 1px solid #98AD86;
      background-repeat: no-repeat;
      background-size: 90%;
      background-position: center;
    }
  }

  .infoContainer {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      .nameAndDistance {
        @include Roboto;
        font-weight: 500;
        font-size: 16px;
        line-height: 18.75px;
        margin-bottom: 4px;

        .name {
          color: $black;
        }
  
        .location {
          margin-left: 8px;
          color: $gray3;
        }
      }

      .address {
        @include Roboto;
        font-weight: 500;
        font-size: 14px;
        line-height: 16.41px;
        color: $gray3;
      }
    }

    .icon {
      flex: none;
      margin-left: 16px;
      color: $gray2;
    }
  }
}

.retailerLocationButton:last-child {
  border-bottom: none;
}

/* Resident Button */

.residentButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #C5D1D4;
  padding: 24px 0;

  .name {
    @include Roboto;
    font-weight: 500;
    font-size: 20px;
    line-height: 23.44px;
  }

  .icon {
    flex: none;
    margin-left: 16px;
    color: $gray2;
  }
}

.residentButton:last-child {
  border-bottom: none;
}

/* Standard Button */

.standardButton {
  width: 100%;
  height: 43px;
  border: 1px solid;
  border-radius: 28px;
  @include Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  padding: 0 40px;
}

.standardButton.primary {
  border-color: $primary1;
  background-color: $primary1;
  color: $white;
}

.standardButton.secondary {
  border-color: $slate;
  background-color: $white;
  color: $black;
}

.standardButton.primary.disabled {
  border-color: $gray1;
  background-color: $gray1;
  color: $gray2;
}

.standardButton.secondary.disabled {
  border-color: $gray1;
  color: $gray2;
}

.standardButton.text {
  border-color: transparent;
  background-color: transparent;
  color: $primary1;
}

.standardButton.text.disabled {
  color: $gray2;
}

/* Step Counter */

.stepCounter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .stepNum {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray1;
    color: $primary1;
    @include Roboto;
    font-weight: 700;
    font-size: 20px;
    line-height: 23.44px;
    text-align: center;
  }

  .stepDivider {
    margin: 8px;
    flex: 1;
    height: 0;
    border-top: 1px solid $gray1;
    border-bottom: 1px solid $gray1;
  }

  .stepNum.disabled {
    color: $gray3;
  }

  .stepNum.completed {
    background-color: $primary2;
  }

  .stepNum.active {
    background-color: $primary1;
    color: $white;
  }
}

/* Locker Type Button */

.lockerTypeButton {
  flex: 1;
  max-width: 108.67px;
  margin: 0 4px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    margin-bottom: 8px;

    img {
      width: 40px;
      height: auto;
    }
  }

  .label {
    @include Nunito;
    color: $white;
    font-weight: 800;
    font-size: 11px;
    line-height: 15.03px;
    text-align: center;
    text-transform: capitalize;
  }
}

.lockerTypeButton.chiller {
  background-color: $chillerhue;
}

.lockerTypeButton.freezer {
  background-color: $freezerhue;
}

.lockerTypeButton.ambient {
  background-color: $ambienthue;
}

/* DSP Retailer Heading */

.dspRetailerHeading {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .retailerImage {
    background-color: $white;
    width: 75px;
    height: 75px;
    border: 1px solid $primary2;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .placeholder {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 40px;
        height: auto;
      }
    }
  }

  p {
    @include Roboto;
    font-weight: 400;
    font-size: 20px;
    line-height: 23.44px;
    margin-left: 12px;
  }
}

/* Locker Diagram */

.lockerDiagram {
  display: grid;

  .heading {
    border-radius: 3px;
    height: 28px;
    padding: 4px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
      margin-right: 4px;

      img {
        height: 12px;
        width: auto;
      }
    }

    .text {
      @include Roboto;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: $white;
      text-transform: capitalize;
    }
  }

  .locker {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $black;
    border-radius: 2px;
    background-color: $white;
    margin: 1px;
    height: 47px;
    padding: 4px;

    .label {
      @include Roboto;
      font-weight: 400;
      font-size: 12px;
      line-height: 14.06px;
    }

    .check {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url('../assets/icons/circle-check.png');
    }
  }
}

.lockerDiagramAlt {
  display: grid;

  .locker {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: $white;
    margin: 1px;
    padding: 4px;

    .label {
      @include Roboto;
      font-weight: 900;
      font-size: 16px;
      line-height: 18.75px;
      color: $black;
    }
  }
}
