.formItemWrapper {
  padding-bottom: 16px;
}

.formItem {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 12px;

  .label {
    @include Roboto;
    font-size: 16px;
    line-height: 18.75px;
    margin-bottom: 12px;
  }

  .inputContainer {
    height: 55px;
    border-radius: 6px;
    border: 1px solid #C5D1D4;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input {
      @include Roboto;
      height: 100%;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      border-radius: 6px;
      border: none;
      padding: 16px;
      flex-grow: 1;
      flex-shrink: 1;

      &::placeholder {
        color: $gray3;
      }
    }
  }

  .specialInputContainer {
    height: 55px;
    border-radius: 6px;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input {
      @include Roboto;
      height: 100%;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      border-radius: 6px;
      border: 1px solid #C5D1D4;
      padding: 16px;
      flex-grow: 1;
      flex-shrink: 1;

      &::placeholder {
        color: $gray3;
      }
    }
  }
}
