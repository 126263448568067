/* Retailer Selection */

.SelectRetailer {
  .content {
    padding-top: 24px;
    padding-bottom: 16px;

    .imageContainer {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-bottom: 40px;
    }

    .headingContainer {
      margin-bottom: 40px;
      padding: 0 26px;

      .heading {
        @include Roboto;
        font-weight: 700;
        font-size: 20px;
        line-height: 29.22px;
        text-align: center;
      }

      .subHeading {
        @include BodyFont;
        text-align: center;
        padding-top: 16px;
      }
    }

    .retailerSelect {
      padding: 0 16px;

      .heading {
        @include Roboto;
        font-weight: 700;
        font-size: 16px;
        line-height: 23.38px;
        text-align: center;
        margin-bottom: 16px;
      }

      .retailerList {
        display: flex;
        flex-wrap: wrap;
        max-width: 340px;
        margin: 0 auto;
      }
    }
  }
}

.SelectRetailerLocation {
  .content {
    padding: 24px 16px 16px 16px;

    .retailerLocationSelect {
      .heading {
        @include Roboto;
        font-weight: 700;
        font-size: 16px;
        line-height: 23.38px;
        text-align: center;
      }

      .retailerList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.EnterRetailer {
  .content {
    padding: 24px 16px 16px 16px;

    .heading {
      @include Roboto;
      font-weight: 700;
      font-size: 16px;
      line-height: 23.38px;
      margin-bottom: 16px;
    }
  }
}

/* Order Creation */

.EnterUnitNumber, .SelectResident, .SelectLockerType {
  .currentStepContainer {
    width: 100%;
    padding: 16px 40px 0 40px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 40px 24px;

    .lockerTypeButtons {
      display: flex;
    }

    .lockerSelectNote {
      @include Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 18.75px;
      color: $gray4;
      margin-top: 24px;
    }
  }
}

.SelectResident {
  .dspRetailerHeading {
    margin-bottom: 16px; // Compensating for resident button padding
  }
}

/* Locker Management */

.locker-container {
  display: grid;
  gap: 5px;
  width: 100%;
}

.locker {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  position: relative;  /* set the locker box to relative positioning */
}

.locker::before {
  content: '';
  display: block;
  padding-bottom: 50%; /* makes the box half as tall as it is wide */
}

.locker.chiller {
  background-color: #CCCCCC; /* Chiller locker color */
}

.locker.freezer {
  background-color: #CCCCCC; /* Freezer locker color */
}

.openLockerBox.chiller {
  background-color: #9DCF76; /* Chiller locker color */
}

.openLockerBox.freezer {
  background-color: #4296D2; /* Freezer locker color */
}

.locker.opened.chiller {
  background-color: #9DCF76; /* Opened locker color */
}

.locker.opened.freezer {
  background-color: #4296D2; /* Opened locker color */
}

.locker.filled.chiller {
  background-color: #9DCF76; /* Opened locker color */
}

.locker.filled.freezer {
  background-color: #4296D2; /* Opened locker color */
}

.LockerOpened,
.ManageLockers,
.OrderComplete {
  .currentStepContainer {
    width: 100%;
    padding: 16px 40px 0 40px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px 24px 40px 24px;

    .dspRetailerHeading {
      margin-bottom: 24px;
    }

    .openLockersInfo {
      margin-bottom: 24px;

      .heading {
        @include Roboto;
        font-weight: 700;
        font-size: 32px;
        line-height: 37.5px;
        text-align: center;
        text-transform: capitalize;
      }

      .openLockerBoxContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .openLockerBox {
          width: 100px;
          height: 100px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 16px 8px 0 8px;
  
          .boxNumber {
            color: $white;
            @include Roboto;
            font-weight: 900;
            font-size: 40px;
            line-height: 46.88px;
            text-align: center;
          }
        }
      }
    }

    .lockerDiagramContainer {
      padding: 0 12px;
    }

    .lockerMessage {
      @include Roboto;
      font-weight: 700;
      font-size: 20px;
      line-height: 29.22px;
      text-align: center;
    }

    .moreOptions {
      .header {
        @include Roboto;
        font-weight: 700;
        font-size: 16px;
        line-height: 23.38px;
        margin-bottom: 8px;
      }

      .lockerTypeButtons {
        display: flex;
      }
    }
  }
}

.LockerOpened, .OrderComplete {
  .content {
    .lockerDiagramContainer {
      margin-bottom: 24px;
    }
  }
}

.ManageLockers {
  .content {
    .lockerDiagramContainer {
      margin-bottom: 24px;
    }
  }
}
