/* Grayscale */
$white: #FFFFFF;

$gray1: #EFEFEF;
$gray2: #B1B1B1;
$gray3: #757575;
$gray4: #333333;

$black: #1E1E2F;

/* Primary */
$primary1: #79b575;
$primary2: #457f41;

/* Secondary */

/* Other */
$gray: #CCCCCC;
$slate: #98ADB6;
$chillerhue: #9DCF76;
$freezerhue: #4296D2;
$ambienthue: #98ADB6;

