/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');

@mixin Inter {
  font-family: 'Inter', sans-serif;
}

@mixin Roboto {
  font-family: 'Roboto', sans-serif;
}

@mixin Nunito {
  font-family: 'Nunito', sans-serif;
}

/* Import global stylesheets */
@import './colors.scss';
@import './resets.scss';
@import './master.scss';
@import './forms.scss';
@import './modals.scss';
@import './components.scss';
@import './dsp.scss';
